export {
  grayScale,
  yellow,
  blue,
  red,
  orange,
  green,
  pink,
  getOpacityColor,
} from "./colors";
