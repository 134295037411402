import {
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  createContext,
} from "react";

type ModalComponentType = "COMMON"; // TODO: ModalContainer와 동기화 되어야 함.

export interface ModalStateType {
  type?: ModalComponentType | null;
  props?: object | null;
  CustomComponent?: ReactNode;
}

const initialState: ModalStateType = {
  type: null,
  props: null,
  CustomComponent: null,
};

export const ModalStateContext = createContext<ModalStateType>(initialState);
export const ModalSetterContext = createContext<
  Dispatch<SetStateAction<ModalStateType>>
>(() => null);

const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<ModalStateType>(initialState);
  return (
    <ModalSetterContext.Provider value={setState}>
      <ModalStateContext.Provider value={state}>
        {children}
      </ModalStateContext.Provider>
    </ModalSetterContext.Provider>
  );
};

export default ModalProvider;
