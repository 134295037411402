import type { IconProps } from "../types";

export const FooterBlogIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      fill="#8B8B8B"
      d="M5 1h8.115q1.727 0 3.08.755 1.38.755 2.129 2.118.777 1.365.777 3.106 0 .987-.46 2.06a6.1 6.1 0 0 1-1.18 1.887l.834.552q1.18.783 1.928 2.205.777 1.423.777 2.874 0 1.857-.777 3.338a5.7 5.7 0 0 1-2.13 2.292Q16.743 23 15.014 23H5zm9.209 18.227q1.122 0 1.755-.697.662-.725.662-1.973 0-1.365-.662-2.148-.633-.784-1.755-.784H9.374v5.602zm-1.64-9.375q1.005-.03 1.582-.667.604-.668.604-1.77 0-1.22-.604-1.916-.605-.726-1.669-.726H9.374v5.08z"
      stroke={strokeColor}
    />
  </svg>
);
