import { useEffect } from "react";

/**
 * 모바일의 브라우저의 하단바 제외하고 실질적으로 브라우저에서 보여지는 화면 크기 계산
 */
export const useAdjustVH = () => {
  useEffect(() => {
    const adjustVH = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    // 컴포넌트 마운트 시에 한 번 실행하여 초기 vh 값을 설정
    adjustVH();

    // 화면 크기가 변경될 때마다 vh 값을 재계산
    window.addEventListener("resize", adjustVH);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", adjustVH);
    };
  }, []);
};
