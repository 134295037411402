import styled from "styled-components";

import { getOpacityColor, grayScale } from "@enkor/design/colors";
import { Z_INDEX } from "@enkor/design/variables/z-index";
import { APP_SIZE } from "@styles/variables";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  z-index: ${Z_INDEX.MODAL};
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  background-color: ${getOpacityColor(grayScale.black, 0.6)};

  transform: translateX(-50%);
`;

export const AppModalArea = styled.section`
  position: relative;
  display: flex;

  width: 100%;
  max-width: ${APP_SIZE.MAX_WIDTH}px;
  height: 100%;
`;
