import { useEffect } from "react";
import { useRouter } from "next/router";

import useWebStorage, { STORAGE_KEY } from "./useWebStorage";

/**
 * route 히스토리를 세션에 저장하는 커스텀 훅입니다.
 */
export const useTrackHistory = () => {
  const router = useRouter();
  const [history, setHistory] = useWebStorage(
    STORAGE_KEY.COMMON_HISTORY,
    [] as string[],
    "sessionStorage",
  );
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const updatedHistory = history ? [...history, url] : [url];
      setHistory(updatedHistory);
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router, history, setHistory]);
};
