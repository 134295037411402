import type { IconProps } from "../types";

export const FooterYoutubeIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      fill="#8B8B8B"
      d="M23.5 6.5a2.83 2.83 0 0 0-.778-1.28 3.05 3.05 0 0 0-1.344-.741C19.505 4 12 4 12 4s-7.505 0-9.378.476c-.509.13-.972.386-1.345.74-.372.355-.64.797-.776 1.28C0 8.28 0 12 0 12s0 3.72.5 5.5c.277.984 1.091 1.759 2.122 2.021C4.495 20 12 20 12 20s7.505 0 9.378-.479c1.034-.262 1.845-1.037 2.121-2.02C24 15.72 24 12 24 12s0-3.72-.5-5.5M9.615 15.413V8.586l6.214 3.389z"
      stroke={strokeColor}
    />
  </svg>
);
