import { ColorType } from "../../colors/types";

import * as S from "./Divider.style";

interface DividerProps {
  size?: S.DividerSizeType;
  spacing?: S.DividerSpacingType | number;
  bgColor?: ColorType;
}

export const Divider = ({
  size = "medium",
  spacing = "wide",
  bgColor,
}: DividerProps) => {
  return <S.StyledDivider size={size} spacing={spacing} $bgColor={bgColor} />;
};
