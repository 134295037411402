import type { IconProps } from "../types";

export const FooterStaySymbolIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <g clipPath="url(#FooterStaySymbol_svg__a)">
      <path
        fill="#C1C1C1"
        d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12"
      />
      <path
        fill="#515151"
        d="M16.738 7.11a6.816 6.816 0 0 0-3.075-1.664h-.027a6 6 0 0 0-.257-.054l-.054-.013-.242-.04-.07-.013-.222-.027h-.084l-.223-.019h-.617c-.037 0-.558.032-.62.038l-.129.015c-.06 0-.434.065-.492.078l-.306.065-.1.028-.348.099c-.053.017-.407.139-.445.16l-.149.061a9 9 0 0 0-.293.131l-.14.068c-.052.025-.239.126-.283.151l-.151.089-.127.076-.151.101q-.06.037-.118.078l-.156.116-.107.078-.027.023-.144.116-.085.07c-.082.072-.164.146-.244.211s-.147.143-.223.21q-.022.027-.044.05c-1.143 1.213-1.752 2.795-1.703 4.421s.752 3.173 1.965 4.324l3.655 3.465 1.095 1.038 4.752-4.503a6.4 6.4 0 0 0 1.46-2.072 6.1 6.1 0 0 0 .51-2.443 6.1 6.1 0 0 0-.516-2.443 6.4 6.4 0 0 0-1.465-2.068"
      />
      <path
        fill="#C1C1C1"
        d="M15.43 12a3.429 3.429 0 1 1-6.858 0 3.429 3.429 0 0 1 6.857 0"
      />
    </g>
    <defs>
      <clipPath id="FooterStaySymbol_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
