import type { IconProps } from "../types";

export const KrwIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14.667 13.5 14 16l-2-5.5-2 5.5-.667-2.5m5.334 0 .8-3m-.8 3H17m-1.533-3 .533-2m-.533 2H17m-9-2 .533 2m0 0 .8 3m-.8-3H7m2.333 3H7M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0"
      stroke={strokeColor}
    />
  </svg>
);
