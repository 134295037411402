import type { IconProps } from "../types";

export const ToastMachineIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      strokeLinecap="round"
      strokeWidth={2}
      d="m3.009 11.759-1 .008M8.111 20.665v1.333M15.889 20.665v1.333"
      stroke={strokeColor}
    />
    <path
      strokeWidth={2}
      d="M3.222 10.443a3 3 0 0 1 3-3H18a3 3 0 0 1 3 3v7.222a2 2 0 0 1-2 2H5.222a2 2 0 0 1-2-2z"
      stroke={strokeColor}
    />
    <path
      fill="#515151"
      fillRule="evenodd"
      d="M7.17 1.75a2.726 2.726 0 0 0-1.54 4.975q.074.251.211.469a4 4 0 0 1 .381-.018H18q.192 0 .38.018.138-.218.212-.47a2.726 2.726 0 0 0-1.54-4.975zm-.726 2.726c0-.401.325-.726.726-.726h9.882a.726.726 0 0 1 .255 1.406l-.74.278.081.63H7.574l.08-.63-.74-.278a.73.73 0 0 1-.47-.68"
      clipRule="evenodd"
      stroke={strokeColor}
    />
    <circle
      cx={17.667}
      cy={16.332}
      r={1.111}
      fill="#515151"
      stroke={strokeColor}
    />
  </svg>
);
