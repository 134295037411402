import { ReactNode } from "react";

type ProviderType = [
  ({ children }: { children: ReactNode }) => JSX.Element, // NOTE: Provider Component
  Record<string, unknown>?, // NOTE: Provider Props (optional)
];

interface ComposerProps {
  components: ProviderType[];
  children: ReactNode;
}

const Composer = ({ components = [], children }: ComposerProps) => {
  return (
    <>
      {components.reduceRight((acc, [Comp, compProps]) => {
        return <Comp {...(compProps && { ...compProps })}>{acc}</Comp>;
      }, children)}
    </>
  );
};

export default Composer;
