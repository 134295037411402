import styled, { css } from "styled-components";

export const SpinnerWrapper = styled.div<{
  position?: "static" | "absolute" | "fixed";
}>`
  position: ${({ position }) => position};
  display: flex;
  justify-content: center;

  ${({ position }) =>
    position !== "static" &&
    css`
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);
    `};
`;
