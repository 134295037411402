import { ReactNode } from "react";

import { Slide, ToastContainer, ToastOptions, toast } from "react-toastify";
import styled from "styled-components";

import { getOpacityColor, grayScale, red } from "@enkor/design/colors";
import { APP_SIZE } from "@styles/variables";

const defaultToastOption: ToastOptions = {
  position: "top-center",
  autoClose: 2000,
  transition: Slide,
  hideProgressBar: true,
  closeOnClick: true,
  draggable: true,
  pauseOnHover: true,
  closeButton: false,
  icon: false,
  delay: 100,
};

export const ToastSuccess = (
  toastContent: ReactNode,
  options?: ToastOptions,
) => {
  return toast.success(toastContent, {
    ...defaultToastOption,
    ...options,
  });
};

export const ToastError = (toastContent: ReactNode, options?: ToastOptions) => {
  return toast.error(toastContent, {
    ...defaultToastOption,
    ...options,
  });
};

const CustomToastContainer = styled(ToastContainer)`
  width: 100%;
  max-width: ${APP_SIZE.MAX_WIDTH}px;
  padding: 0 30px 10px;
  margin-top: env(safe-area-inset-top);

  .Toastify__toast {
    padding: 14px 16px;
    border-radius: 8px;

    background-color: ${getOpacityColor(grayScale.white, 0.85)};

    color: ${grayScale[300]};
  }

  /* 토스트 컨텐츠에 들어가는 요소 기본 스타일 */

  .Toastify__toast-body * {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    white-space: pre-line;
  }

  .Toastify__toast-icon {
    width: 20px;
    height: 20px;
  }

  .Toastify__toast--success {
    background-color: ${getOpacityColor(grayScale.white, 0.85)};

    & * {
      color: ${grayScale[300]};
    }
  }

  .Toastify__toast--error {
    background-color: ${red.main};

    & * {
      background-color: ${red.main};

      color: ${grayScale.white};
    }
  }

  /* 토스트 애니메이션 Custom */

  .Toastify--animate {
    animation-duration: 0.3s;
    animation-fill-mode: both;
  }
`;

export default CustomToastContainer;
