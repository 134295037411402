import type { IconProps } from "../types";

export const PinFilledIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      fill="#515151"
      fillRule="evenodd"
      d="M13.793 3.293a1 1 0 0 1 1.414 0l5.5 5.5a1 1 0 0 1-1.176 1.591l-3.171 3.171-1.424 3.797a1 1 0 0 1-.229.356l-1.5 1.5a1 1 0 0 1-1.414 0L9 16.414l-3.793 3.793a1 1 0 0 1-1.414-1.414L7.586 15l-2.793-2.793a1 1 0 0 1 0-1.414l1.5-1.5a1 1 0 0 1 .356-.229l3.797-1.424 3.171-3.171a1 1 0 0 1 .176-1.176"
      stroke={strokeColor}
    />
  </svg>
);
