import type { IconProps } from "../types";

export const CrownFilledIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      fill="#fff"
      d="M4.05 5.802 7.855 9.15l3.39-4.174a1 1 0 0 1 1.565.017l3.235 4.156 3.928-3.396a1 1 0 0 1 1.643.9L20.116 17H3.923L2.4 6.7a1 1 0 0 1 1.65-.898M4 18h16v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1z"
      stroke={strokeColor}
    />
  </svg>
);
