import type { IconProps } from "../types";

export const PlayStoreIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox}
    width={size}
    height={size}
    style={style}
    fill={fillColor}
    className={className}
  >
    <defs>
      <style>{".PlayStore_svg__cls-1{fill:#515151;fill-rule:evenodd}"}</style>
    </defs>
    <g
      id="PlayStore_svg___\uB808\uC774\uC5B4_1"
      data-name="\uB808\uC774\uC5B4_1"
    >
      <path
        d="m16.99 16.07 3.38-1.94c.76-.41 1.27-1.2 1.27-2.11s-.51-1.7-1.26-2.1-3.39-1.96-3.39-1.96l-4.05 4.05L17 16.07ZM11.43 13.51l-8.42 8.42a2.39 2.39 0 0 0 2.98.45l.03-.02 9.06-5.2-3.66-3.66ZM9.92 12.01 2.36 4.44v15.13zM11.43 10.5l3.65-3.65-9.07-5.22c-.36-.22-.79-.34-1.25-.34-.69 0-1.32.31-1.77.79l8.43 8.43Z"
        className="PlayStore_svg__cls-1"
      />
    </g>
  </svg>
);
