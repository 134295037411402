import { CloseIcon } from "@enkor/design/icons";
import { grayScale } from "@enkor/design/colors";
import useModalState from "@logics/hooks/useModalState";

import * as S from "./CommonModal.style";

interface CommonModalProps {
  withXIcon?: boolean;
  modalTitle: string;
  modalDescription: string;
  buttonText?: string;
  onClickConfirm: () => void;
}

// TODO: 공용으로 쓰일 수 있도록 general하게 추상화 필요
const CommonModal = ({
  modalTitle,
  modalDescription,
  buttonText,
  onClickConfirm,
  withXIcon = true,
}: CommonModalProps) => {
  const { closeModal } = useModalState();

  return (
    <S.Container>
      <S.ModalHeader name="h6">{modalTitle}</S.ModalHeader>
      <S.ModalContent name="m">{modalDescription}</S.ModalContent>
      <S.ConfirmButton type="button" onClick={onClickConfirm}>
        {buttonText || "OK"}
      </S.ConfirmButton>

      {withXIcon && (
        <S.CloseButton type="button" onClick={closeModal}>
          <CloseIcon strokeColor={grayScale.white} />
        </S.CloseButton>
      )}
    </S.Container>
  );
};

export default CommonModal;
