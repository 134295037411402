import type { IconProps } from "../types";

export const SupportIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m18.364 5.636-3.536 3.536m3.536-3.536a9 9 0 0 0-12.728 0m12.728 0a9 9 0 0 1 0 12.728m-3.536-9.192a4 4 0 0 0-5.656 0m5.656 0a4 4 0 0 1 0 5.656m0 0 3.536 3.536m-3.536-3.536a4 4 0 0 1-5.656 0m9.192 3.536a9 9 0 0 1-12.728 0m3.536-9.192L5.636 5.636m3.536 3.536a4 4 0 0 0 0 5.656M5.636 5.636a9 9 0 0 0 0 12.728m3.536-3.536-3.536 3.536"
      stroke={strokeColor}
    />
  </svg>
);
