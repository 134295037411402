import { AuthProviderType } from "@models/client";

import { ampli, BaseEvent, DefaultConfiguration } from "./ampli";
import WebViewBridge from "./webViewBridge";

export class AmplitudeTracking {
  private static amplitudeInstance: AmplitudeTracking;
  private currentUrl!: URL;
  private pathname!: string;
  private viewType!: string | null;

  constructor() {
    this.loadConfiguration();
    this.initializeLocation();
  }

  private loadConfiguration() {
    ampli.load({
      client: {
        apiKey: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || "",
        configuration: {
          ...DefaultConfiguration,
          logLevel: 3,
          defaultTracking: {
            attribution: {
              excludeReferrers: ["localhost:3000"],
            },
            fileDownloads: true,
            formInteractions: true,
            pageViews: true,
            sessions: !WebViewBridge.isInAppWebView(),
          },
          minIdLength: 1,
          serverUrl:
            process.env.NEXT_PUBLIC_NODE_ENV !== "develop"
              ? process.env.NEXT_PUBLIC_AMPLITUDE_SERVER_URL
              : undefined,
        },
      },
      disabled: !process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
    });
  }

  private initializeLocation() {
    this.currentUrl = new URL(window.location.href);
    this.pathname = this.currentUrl.pathname;
    this.viewType = this.currentUrl.searchParams.get("viewType");
  }

  public static getInstance(): AmplitudeTracking {
    if (!AmplitudeTracking.amplitudeInstance) {
      return (AmplitudeTracking.amplitudeInstance = new AmplitudeTracking());
    }

    return this.amplitudeInstance;
  }

  public identifyUser<T extends { id: number; authProvider: AuthProviderType }>(
    user: T,
  ) {
    if (!user) return;

    ampli.identify(String(user.id), {
      user_id: String(user.id),
      extra: {
        auth_provider: user.authProvider,
      },
    });
  }

  public trackEvent<T extends object>(
    eventName: string,
    properties?: T,
    ...rest: any
  ) {
    const event: BaseEvent = {
      event_type: eventName,
      event_properties: properties || {},
      ...rest,
    };

    ampli.track(event);
  }

  public initializeAppSession(
    sessionId: number,
    userId: number,
    authProvider: AuthProviderType,
  ) {
    ampli.client.setSessionId(sessionId);

    ampli.identify(String(userId), {
      user_id: String(userId),
      extra: {
        auth_provider: authProvider,
      },
    });
  }
}
