import { useEffect } from "react";
import { useRouter } from "next/router";

import { AmplitudeTracking } from "@logics/utils/amplitude";
import { AuthProviderType } from "@models/client";

/**
 * Amplitude에서 사용하는 쿼리 매개변수 상수
 */
export const AMPLITUDE_QUERY_PARAMS = ["sessionId", "userId", "authProvider"];

export interface AppDataQueryType {
  sessionId: number;
  userId: number;
  authProvider: AuthProviderType;
}
/**
 * [amplitude]
 * 앱에서 session start, end를 제어합니다.
 * session 동기화를 위해 앱에서 sessionId, userId, authProvider를 받아 amplitude에 전달합니다.
 *
 */
const useAppDataReceiver = () => {
  const { query } = useRouter();
  const routerQuery = query as unknown as AppDataQueryType;

  useEffect(() => {
    if (!routerQuery.sessionId || typeof window === "undefined") return;

    const { sessionId, userId, authProvider } = routerQuery;

    AmplitudeTracking.getInstance().initializeAppSession(
      sessionId,
      userId,
      authProvider,
    );
  }, [routerQuery]);
};

export default useAppDataReceiver;
