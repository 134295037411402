export const APP_SIZE = {
  MIN_WIDTH: 270,
  MAX_WIDTH: 520,
  MIN_HEIGHT: 932,
};

export const HEADER_SIZE = {
  HEIGHT: 52,
};

export const FOOTER_SIZE = {
  HEIGHT: 255,
};
