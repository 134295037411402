import { useEffect, useState } from "react";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";

import { appWithTranslation } from "next-i18next";
import NextNProgress from "nextjs-progressbar";

import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
  dehydrate,
} from "@enkor/react-query";

import { pink } from "@enkor/design/colors";
import GlobalStyles from "@styles/GlobalStyles";
import ModalProvider from "@context/ModalProvider";
import RemoteConfigProvider from "@logics/providers/RemoteConfigProvider";
import Composer from "@logics/utils/Composer";
import CustomToastContainer from "@logics/utils/toast";
import ModalContainer from "@domains/common/views/modal/ModalContainer/ModalContainer";
import GAScript from "@logics/scripts/GAScript";
import { useTrackHistory } from "@logics/hooks/useTrackHistory";
import { useAdjustVH } from "@logics/hooks/useAdjustVH";
import useAppDataReceiver from "@logics/hooks/useAppDataReceiver";
import WebViewBridge from "@logics/utils/webViewBridge";
import MetaTags from "@domains/common/views/components/MetaTags/MetaTags";

import "react-toastify/dist/ReactToastify.css";

const CommunityApp = ({ Component, pageProps }: AppProps) => {
  const { pathname } = useRouter();

  const [isLandingPage, setIsLandingPage] = useState(true);
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60 * 1000, // NOTE: 클라이언트에서 즉시 재요청하는 것을 피하기 위해
          },
        },
      }),
  );

  useTrackHistory();
  useAdjustVH();
  useAppDataReceiver();

  useEffect(() => {
    setIsLandingPage(pathname === "/");
  }, [pathname]);

  return (
    <>
      <GAScript />
      <MetaTags />

      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={dehydrate(queryClient)}>
          <NextNProgress
            showOnShallow={false}
            color={pink.main}
            options={{ showSpinner: false }}
            stopDelayMs={200}
            height={3}
          />

          <Composer components={[[ModalProvider], [RemoteConfigProvider]]}>
            <GlobalStyles
              $isWebview={WebViewBridge.isInAppWebView()}
              $isFullPage={isLandingPage}
            />
            <Component {...pageProps} />

            <ModalContainer />
            <CustomToastContainer />
          </Composer>
        </HydrationBoundary>

        {/* FIXME: localhost 런타임 에러 */}
        {/* <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-right" /> */}
      </QueryClientProvider>
    </>
  );
};

export default appWithTranslation(CommunityApp);
