import type { IconProps } from "../types";

export const GooglePlayIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M15.596 7.152 6.014 1.628a2.4 2.4 0 0 0-1.246-.343c-.909 0-1.712.53-2.122 1.281l.09.089 8.692 8.64zM2.357 3.689v16.622L10.72 12zm.29 17.744c.41.751 1.213 1.28 2.121 1.28.446 0 .864-.119 1.223-.33l.029-.016 9.592-5.504-4.184-4.158-8.693 8.64zm13.864-5.086 3.863-2.216a2.392 2.392 0 0 0 .014-4.215l-.008-.006-3.886-2.24L12.137 12z"
      clipRule="evenodd"
      stroke={strokeColor}
    />
  </svg>
);
