import type { IconProps } from "../types";

export const LoginGoogleIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      fill="#FFC107"
      d="M23.767 9.65H22.8V9.6H12v4.8h6.782c-.99 2.794-3.648 4.8-6.782 4.8a7.2 7.2 0 0 1 0-14.4c1.835 0 3.505.692 4.777 1.823L20.17 3.23A11.95 11.95 0 0 0 12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12c0-.805-.083-1.59-.233-2.35"
      stroke={strokeColor}
    />
    <path
      fill="#FF3D00"
      d="m1.656 6.275 3.821 2.828c1.034-2.583 3.538-4.407 6.468-4.407 1.778 0 3.397.677 4.629 1.783l3.29-3.32C17.785 1.205 15.007 0 11.943 0 7.479 0 3.605 2.546 1.657 6.275"
      stroke={strokeColor}
    />
    <path
      fill="#4CAF50"
      d="M12.042 24c3.013 0 5.751-1.231 7.821-3.234l-3.61-3.262a6.67 6.67 0 0 1-4.211 1.513c-3.035 0-5.61-2.065-6.582-4.948l-3.804 3.13c1.931 4.033 5.852 6.8 10.386 6.8"
      stroke={strokeColor}
    />
    <path
      fill="#1976D2"
      d="M24.172 10.047h-1V10H12v4.571h7.016c-.49 1.267-1.372 2.373-2.537 3.184l.002-.002 3.842 2.994c-.272.227 4.09-2.747 4.09-8.461 0-.767-.085-1.515-.24-2.239"
      stroke={strokeColor}
    />
  </svg>
);
