import type { IconProps } from "../types";

export const SwimmingPoolIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      strokeLinecap="round"
      strokeWidth={2}
      d="m1 16.084 1.068-.92c1.139-.981 2.86-.887 3.905.193v0c1.085 1.122 2.896 1.152 3.997.046v0a2.796 2.796 0 0 1 3.96.007v0a2.816 2.816 0 0 0 3.987.007l.076-.076a2.915 2.915 0 0 1 3.97-.152l1.037.895M1 21.084l1.068-.92c1.139-.981 2.86-.887 3.905.193v0c1.085 1.122 2.896 1.152 3.997.046v0a2.796 2.796 0 0 1 3.96.007v0a2.816 2.816 0 0 0 3.987.007l.076-.076a2.915 2.915 0 0 1 3.97-.152l1.037.895"
      stroke={strokeColor}
    />
    <path
      fill="#515151"
      d="M11 9.917a1 1 0 1 0 2 0zm0-4.5v4.5h2v-4.5zM7 15V5.417H5V15zM9 3.417a2 2 0 0 1 2 2h2a4 4 0 0 0-4-4zm0-2a4 4 0 0 0-4 4h2a2 2 0 0 1 2-2z"
      stroke={strokeColor}
    />
    <path
      fill="#515151"
      fillRule="evenodd"
      d="M11.68 4.066c-.117.36-.18.744-.18 1.142v2.709a1 1 0 0 0 .5.866V5.417c0-.486-.116-.945-.32-1.351m2.165.112a1.708 1.708 0 0 1 3.071 1.03v4.709a1 1 0 1 0 2 0V5.208a3.708 3.708 0 0 0-6.007-2.91c.435.546.759 1.184.936 1.88"
      clipRule="evenodd"
      stroke={strokeColor}
    />
    <path strokeWidth={2} d="M12 6.417h6" stroke={strokeColor} />
  </svg>
);
