import type { IconProps } from "../types";

export const FooterTiktokIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      fill="#8B8B8B"
      d="M22.5 9.834c-2.108.047-4.075-.609-5.76-1.826v8.382c0 3.184-1.967 5.994-4.965 7.118-2.95 1.124-6.322.281-8.383-2.107a7.67 7.67 0 0 1-.984-8.664c1.5-2.762 4.59-4.308 7.775-3.886v4.214a3.54 3.54 0 0 0-3.934 1.265c-.843 1.264-.843 2.903.047 4.12s2.482 1.733 3.887 1.265a3.51 3.51 0 0 0 2.435-3.325V0h4.121c0 .375 0 .702.094 1.077a5.54 5.54 0 0 0 2.53 3.746c.889.61 2.013.937 3.137.937z"
      stroke={strokeColor}
    />
  </svg>
);
