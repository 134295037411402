import React from "react";
import Head from "next/head";

import { grayScale } from "@enkor/design/colors";

interface MetaTagsProps {
  themeColor?: string;
  statusBarStyle?: string;
}

const MetaTags = ({
  themeColor = grayScale[100],
  statusBarStyle = grayScale[100],
}: MetaTagsProps) => {
  return (
    <Head>
      {/* Chrome */}
      <meta name="theme-color" content={themeColor} />
      {/* Safari */}
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content={statusBarStyle}
      />
    </Head>
  );
};

export default MetaTags;
