import type { IconProps } from "../types";

export const RoomIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      fill="#515151"
      d="m7.22 10.484-.64-.768zM17.5 15.5h1zm-.72-5.016.64-.768zm.72 1.536h-1zm-4.22-4.453-.64.768zm-2.56 0-.64-.768zM13 14a1 1 0 1 0-2 0zM5.5 3.5h13v-2h-13zm15 2v13h2v-13zm-2 15h-13v2h13zm-15-2v-13h-2v13zm2 2a2 2 0 0 1-2-2h-2a4 4 0 0 0 4 4zm15-2a2 2 0 0 1-2 2v2a4 4 0 0 0 4-4zm-2-15a2 2 0 0 1 2 2h2a4 4 0 0 0-4-4zm-13-2a4 4 0 0 0-4 4h2a2 2 0 0 1 2-2zm7.14 6.835 3.5 2.917 1.28-1.536-3.5-2.917zm3.86 3.685v3.48h2v-3.48zm-1 4.48h-7v2h7zm-8-1v-3.48h-2v3.48zm.36-4.248 3.5-2.917L10.08 6.8l-3.5 2.917zm-.36.768a1 1 0 0 1 .36-.768L6.58 9.716A3 3 0 0 0 5.5 12.02zm1 4.48a1 1 0 0 1-1-1h-2a3 3 0 0 0 3 3zm8-1a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3zm-.36-4.248a1 1 0 0 1 .36.768h2a3 3 0 0 0-1.08-2.304zM13.92 6.8a3 3 0 0 0-3.84 0l1.28 1.536a1 1 0 0 1 1.28 0zM13 17.5v-2h-2v2zm0-2V14h-2v1.5z"
      stroke={strokeColor}
    />
  </svg>
);
