import type { IconProps } from "../types";

export const ArrowArrayIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    stroke="#515151"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      d="m10 17-4 4m0 0-4-4m4 4V3m8 4 4-4m0 0 4 4m-4-4v18"
      stroke={strokeColor}
    />
  </svg>
);
