import type { IconProps } from "../types";

export const CleaningGuestIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <g clipPath="url(#CleaningGuest_svg__a)">
      <g stroke="#515151" strokeMiterlimit={10} strokeWidth={2}>
        <path d="M8.956 12.874c-.64 0-1.16-.541-1.16-1.209V9.506h-4v9.927c0 .866.673 1.568 1.504 1.568h7.064c.831 0 1.504-.702 1.504-1.568v-1.441c0-2.827-2.198-5.121-4.911-5.121z" />
        <g strokeLinecap="round">
          <path d="M3.796 9.51V8.073a.57.57 0 0 0-.158-.399L1.343 5.28a.52.52 0 0 1-.143-.359V2.614c0-.28.216-.505.485-.505h10.653c.268 0 .485.226.485.505v2.829c0 .28-.216.505-.485.505h-1.123c-1.887 0-3.416 1.595-3.416 3.562m5.186-6.007h1.487M9.021 7.027l2.478 3.019" />
        </g>
      </g>
      <g fill="#515151">
        <ellipse cx={16.562} cy={3.487} rx={0.979} ry={0.994} />
        <ellipse cx={18.521} cy={4.978} rx={0.979} ry={0.994} />
        <ellipse cx={18.521} cy={1.995} rx={0.979} ry={0.994} />
      </g>
      <path
        stroke="#515151"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m20.781 8.719-1.894 1.094.127.221m0 0a3.5 3.5 0 0 1 3.718 2.123 3.5 3.5 0 0 1-.557 3.602m-3.161-5.724.966 1.674m-1.761 6.574 1.894-1.094-.127-.22m0 0a3.5 3.5 0 0 1-3.717-2.123 3.5 3.5 0 0 1 .553-3.597m3.164 5.721-.967-1.674"
      />
    </g>
    <defs>
      <clipPath id="CleaningGuest_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
