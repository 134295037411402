import {
  MouseEvent,
  createElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useRouter } from "next/router";

import { createPortal } from "react-dom";

import { ModalStateContext } from "@context/ModalProvider";
import useModalState from "@logics/hooks/useModalState";

import CommonModal from "../CommonModal/CommonModal";

import * as S from "./ModalContainer.style";

/** domains - auth 모달 import */
/** domains - myAccount 모달 import */

/** 공용으로 사용되는 컴포넌트 */
export const MODAL_COMPONENTS = {
  COMMON: CommonModal,
};

/** 공용 사용 컴포넌트 key  */
export type ModalComponentType = keyof typeof MODAL_COMPONENTS;

const ModalContainer = () => {
  const { events } = useRouter();
  const { closeModal, isModalOpen } = useModalState();
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const { type, props, CustomComponent } = useContext(ModalStateContext);

  const handleBackgroundClick = (e: MouseEvent) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const calculateScrollbarWidth = () => {
    setScrollbarWidth(
      window?.innerWidth - document?.documentElement?.clientWidth,
    );
  };

  // 페이지 변경 시 모달을 닫기 위한 useEffect입니다.
  useEffect(() => {
    const handleCloseModalOnRouteChange = () => isModalOpen && closeModal();

    events.on("routeChangeStart", handleCloseModalOnRouteChange);

    return () => {
      events.off("routeChangeStart", handleCloseModalOnRouteChange);
    };
  }, [events, isModalOpen, closeModal]);

  // 스크롤바 너비를 재계산하기 위한 useEffect입니다.
  useEffect(() => {
    // 모달이 열릴 때 `scrollbarWidth`를 계산합니다.
    calculateScrollbarWidth();

    window.addEventListener("resize", calculateScrollbarWidth);

    return () => {
      window.removeEventListener("resize", calculateScrollbarWidth);
    };
  }, [events]);

  if (!type && !CustomComponent) return null;

  const ModalComponent = MODAL_COMPONENTS[type || "COMMON"];

  return createPortal(
    <S.Container onMouseDown={handleBackgroundClick}>
      <style>{"body { overflow: hidden; }"}</style>

      <S.AppModalArea onMouseDown={handleBackgroundClick}>
        {!type
          ? CustomComponent
          : createElement(ModalComponent, { ...props } as any)}
        {/* TODO: props type 지정 필요 */}
      </S.AppModalArea>
    </S.Container>,
    document.getElementById("modal") as HTMLElement,
  );
};

export default ModalContainer;
