import type { IconProps } from "../types";

export const AppStoreIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      fill="#515151"
      d="M18.37 12.7c.022-1.699.99-3.306 2.527-4.195-.97-1.304-2.592-2.13-4.28-2.18-1.802-.178-3.548 1.015-4.465 1.015-.936 0-2.349-.997-3.87-.968-1.983.06-3.832 1.123-4.797 2.756-2.074 3.383-.527 8.355 1.46 11.089.994 1.339 2.156 2.835 3.676 2.782 1.487-.058 2.043-.894 3.838-.894 1.78 0 2.3.894 3.852.86 1.596-.024 2.601-1.345 3.56-2.697a10.8 10.8 0 0 0 1.629-3.124c-1.876-.747-3.128-2.526-3.13-4.444M15.44 4.527c.87-.985 1.299-2.25 1.195-3.527a5.43 5.43 0 0 0-3.44 1.677c-.863.924-1.311 2.167-1.226 3.396 1.33.013 2.637-.57 3.47-1.546"
      stroke={strokeColor}
    />
  </svg>
);
