import styled from "styled-components";

import { Typo } from "@enkor/design/components";
import { grayScale, getOpacityColor, pink } from "@enkor/design/colors";

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  width: calc(100% - 32px);
  height: fit-content;
  padding: 16px;
  border: 1px solid ${grayScale.main};

  background-color: ${getOpacityColor(grayScale[200], 0.7)};

  transform: translate(-50%, -50%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
`;

export const ModalHeader = styled(Typo.Heading)``;

export const ModalContent = styled(Typo.Text)`
  margin-top: 24px;
  margin-bottom: 16px;
`;

const Button = styled.button`
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const ConfirmButton = styled(Button)`
  background-color: ${pink.main};
`;

export const CancelButton = styled(Button)`
  border: 1px solid ${grayScale.white};

  background-color: transparent;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
`;
