import type { IconProps } from "../types";

export const UsageIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16.75 7.25v9.5M12 10.813v5.937m-4.75-2.375v2.375M4.875 21.5h14.25a2.375 2.375 0 0 0 2.375-2.375V4.875A2.375 2.375 0 0 0 19.125 2.5H4.875A2.375 2.375 0 0 0 2.5 4.875v14.25A2.375 2.375 0 0 0 4.875 21.5"
      stroke={strokeColor}
    />
  </svg>
);
