import SyncLoader from "react-spinners/SyncLoader";
import ClipLoader from "react-spinners/ClipLoader";
import PulseLoader from "react-spinners/PulseLoader";

import { grayScale } from "../../colors";
import { ColorType } from "../../colors/types";

import { SpinnerWrapper } from "./LoadingSpinner.style";

const LoaderComponent = {
  SyncLoader,
  ClipLoader,
  PulseLoader,
} as const;

const DEFAULT_SPINNER_VALUE = {
  MARGIN: 4,
  SIZE: 6,
};

type LoaderType = keyof typeof LoaderComponent;

interface LoadingSpinnerProps {
  loaderType: LoaderType;
  position?: "static" | "absolute" | "fixed";
  color?: ColorType;
  margin?: number;
  size?: number;
}

/**
 * `LoadingSpinner` 컴포넌트는 선택한 로더 타입에 따라 로딩 스피너를 표시합니다.
 *
 * @param loaderType - 사용할 로더의 타입. `SyncLoader`, `BeatLoader`, `PulseLoader` 등이 가능합니다.
 * @param position - 스피너의 위치. 기본값은 "static"입니다.
 * @param color - 스피너의 색상. 기본값은 회색 계열의 흰색입니다.
 * @param margin - 스피너 주변의 여백. 기본값은 4입니다.
 * @param size - 스피너의 크기. 기본값은 6입니다.
 * @returns React.ReactElement `SpinnerWrapper` 요소로 래핑된, 선택된 로더 컴포넌트를 반환합니다.
 *
 * @example
 * ```
 * // SyncLoader를 사용하는 로딩 스피너를 렌더링합니다.
 * <LoadingSpinner loaderType="SyncLoader" />
 * ```
 *
 * ```
 * // ClipLoader를 사용하고, 색상과 크기를 커스텀하는 로딩 스피너를 렌더링합니다.
 * <LoadingSpinner loaderType="ClipLoader" color="#ffffff" size={10} />
 * ```
 */
export const LoadingSpinner = ({
  loaderType,
  position = "static",
  color = grayScale.white,
  margin = DEFAULT_SPINNER_VALUE.MARGIN,
  size = DEFAULT_SPINNER_VALUE.SIZE,
}: LoadingSpinnerProps) => {
  const SelectedLoader = LoaderComponent[loaderType];

  return (
    <SpinnerWrapper position={position}>
      <SelectedLoader color={color} margin={margin} size={size} />
    </SpinnerWrapper>
  );
};
