import type { IconProps } from "../types";

export const FooterKakaotalkIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <g clipPath="url(#FooterKakaotalk_svg__a)">
      <path
        fill="#8B8B8B"
        d="M24 22.125V1.875q0-.777-.55-1.326A1.8 1.8 0 0 0 22.126 0H1.875Q1.098 0 .549.55 0 1.097 0 1.874v20.25q0 .777.55 1.326.548.549 1.325.549h20.25q.777 0 1.326-.55.549-.548.549-1.325M12 3.375q2.652 0 4.895 1.031 2.244 1.031 3.55 2.8t1.305 3.856-1.306 3.858q-1.305 1.767-3.549 2.799-2.243 1.03-4.895 1.031-.884 0-1.728-.12-.91.656-2.417 1.674t-1.708 1.044a.23.23 0 0 1-.227-.013.14.14 0 0 1-.074-.087.3.3 0 0 1-.02-.1v-.04q.014-.068.991-3.537-2.09-1.03-3.328-2.759-1.239-1.727-1.239-3.75 0-2.088 1.306-3.857Q4.86 5.438 7.105 4.406 9.348 3.376 12 3.375m-4.942 9.723V9.75h.857q.187 0 .375-.12a.39.39 0 0 0 .188-.349.46.46 0 0 0-.174-.361q-.175-.148-.335-.148h-2.84q-.508 0-.508.47 0 .254.174.38a.64.64 0 0 0 .388.128h.857v3.348q0 .268.134.442t.375.174a.44.44 0 0 0 .368-.194q.141-.195.141-.422m4.714.616q.429-.174.241-.777-.027-.134-.589-1.68-.562-1.547-.67-1.775-.294-.71-.816-.71-.55 0-.858.71-.08.187-.656 1.795a88 88 0 0 1-.603 1.66q-.066.094-.026.355.04.262.227.369.215.093.422.02a.48.48 0 0 0 .288-.235l.255-.71h1.901q.12.39.175.51.28.615.71.468m2.933 0q.228 0 .415-.14a.45.45 0 0 0 .188-.382.45.45 0 0 0-.167-.375.68.68 0 0 0-.436-.134h-1.218V9.388a.73.73 0 0 0-.134-.421.43.43 0 0 0-.375-.195.43.43 0 0 0-.369.174.73.73 0 0 0-.127.442v3.71a.73.73 0 0 0 .127.442.43.43 0 0 0 .369.174.2.2 0 0 0 .04-.006.2.2 0 0 1 .04-.007.2.2 0 0 1 .04.007.2.2 0 0 0 .04.006zm4.286-.067a.51.51 0 0 0 .18-.402.68.68 0 0 0-.14-.401q-.013-.014-.214-.281a201 201 0 0 1-1.152-1.554l1.273-1.3a.67.67 0 0 0 .194-.374.52.52 0 0 0-.128-.415q-.348-.375-.79.067l-.281.288a246 246 0 0 1-1.353 1.372V9.388a.7.7 0 0 0-.14-.421.43.43 0 0 0-.369-.195.45.45 0 0 0-.375.174.7.7 0 0 0-.133.442v3.71q0 .268.133.442.135.174.375.174a.43.43 0 0 0 .369-.194q.14-.195.14-.422v-.99l.175-.175.187-.2a140 140 0 0 1 1.272 1.727.68.68 0 0 0 .362.268.51.51 0 0 0 .415-.08m-9.71-1.888.656-1.955.67 1.955z"
      />
    </g>
    <defs>
      <clipPath id="FooterKakaotalk_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
