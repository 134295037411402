import { useContext } from "react";

import {
  ModalSetterContext,
  ModalStateContext,
  ModalStateType,
} from "@context/ModalProvider";

const useModalState = () => {
  const setModalState = useContext(ModalSetterContext);
  const { type, CustomComponent } = useContext(ModalStateContext);

  const openModal = ({
    type,
    props,
    CustomComponent = null,
  }: ModalStateType) => {
    setModalState({ type, props, CustomComponent });
  };

  const closeModal = () => {
    setModalState({ type: null, props: null, CustomComponent: null });
  };

  return {
    openModal,
    closeModal,
    isModalOpen: !!type || !!CustomComponent,
    modalType: type,
  };
};

export default useModalState;
