import type { IconProps } from "../types";

export const ArrowsExpandIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20 20h-4M4 8V4zm0-4h4zm0 0 5 5zm16 4V4zm0-4h-4zm0 0-5 5zM4 16v4zm0 4h4zm0 0 5-5zm16 0-5-5zm0 0v-4z"
      stroke={strokeColor}
    />
  </svg>
);
