import type { IconProps } from "../types";

export const WaterDispenser2Icon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M13.5 16.262c0 1.09-.676 1.778-1.75 1.778S10 17.353 10 16.262c0-1.172 1.255-2.753 1.64-3.21a.15.15 0 0 1 .11-.052.14.14 0 0 1 .11.052c.386.457 1.64 2.038 1.64 3.21Z"
      stroke={strokeColor}
    />
    <rect
      width={15}
      height={19}
      x={4.5}
      y={2}
      stroke="#515151"
      strokeWidth={2}
      rx={2}
    />
    <path
      strokeWidth={2}
      d="M9 6h6v2a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2zM4.5 6h15"
      stroke={strokeColor}
    />
    <path
      strokeLinecap="round"
      strokeWidth={2}
      d="M3 21h18"
      stroke={strokeColor}
    />
  </svg>
);
