import { createGlobalStyle } from "styled-components";

import { getOpacityColor, grayScale } from "@enkor/design/colors";
import { removeScrollbar } from "@enkor/design/mixins";

import { APP_SIZE } from "./variables";

const GlobalStyles = createGlobalStyle<{
  $isWebview?: boolean;
  $isFullPage?: boolean;
}>`
  /* NOTE: 디자인 시스템 default text color override */

  :root {
    --default-color: ${grayScale.white};
  }

  /* http://meyerweb.com/eric/tools/css/reset/ 
  v2.0 | 20110126
  License: none (public domain)
  */

  body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    vertical-align: baseline;
    overflow: initial;

    margin: 0;
    padding: 0;
    border: 0;

    color: ${grayScale.white};
    font-size: 100%;
    font-family: "SUIT", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif;
    line-height: normal;

    -webkit-tap-highlight-color: transparent;
  }
  
  /* HTML5 display-role reset for older browsers */

  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    overflow-x: ${({ $isFullPage }) => ($isFullPage ? "hidden" : "auto")};

    min-width: ${({ $isFullPage }) => ($isFullPage ? "unset" : `${APP_SIZE.MIN_WIDTH}px`)};
    max-width: ${({ $isFullPage }) => ($isFullPage ? "unset" : `${APP_SIZE.MAX_WIDTH}px`)};
    margin: 0 auto;

    ${({ $isWebview }) => ($isWebview ? removeScrollbar : "")}
  }

  body {
    box-sizing: border-box;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    margin: 0;
    padding: 0;

    background-color: ${grayScale[200]};
    background-attachment: fixed;
    background-repeat: no-repeat;

    font-family: "SUIT", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif;
    line-height: 1;
  }

  #__next {
    overflow: initial;

    min-height: 100vh;
    min-height: calc((var(--vh, 1vh) * 100));
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none; 
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  a{
    color: inherit;
    text-decoration: none;
  }

  *{
    box-sizing: border-box;
  }

  input, textarea {
    border: none;

    background-color: ${getOpacityColor(grayScale.black, 0.2)};

    color: ${grayScale.white};
    font-family: "SUIT", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif; 

    -moz-user-select: auto;
    -webkit-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;

    &:focus {
      outline: none;
    }

    /* NOTE: input에서 자동 완성을 선택해서 값을 적용했을 때의 style */

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0px 1000px ${grayScale.black} inset !important;
      -webkit-text-fill-color: ${grayScale.white} !important;
    }
  }

  button {
    padding: 0;
    border: none;

    background: none;

    color: ${grayScale.white};
    font-family: "SUIT", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif;

    cursor: pointer;
  }

  /* Font */

  @font-face {
    font-weight: 400;
    font-family: "SUIT";
    src: url('/font/SUIT-Light.woff2') format('truetype');
  }

  @font-face {
    font-weight: 500 600;
    font-family: "SUIT";
    src: url('/font/SUIT-Regular.woff2') format('truetype');
  }

  @font-face {
    font-weight: 700;
    font-family: "SUIT";
    src: url('/font/SUIT-SemiBold.woff2') format('truetype');
  }

  @font-face {
    font-weight: 800;
    font-family: "SUIT";
    src: url('/font/SUIT-Bold.woff2') format('truetype');
  }

  /* Custom Landing Font */

  @font-face {
    font-weight: 300;
    font-family: "Jamsil";
    src: url('/font/The-Jamsil-OTF-1-Thin.otf') format('truetype');
  }

  @font-face {
    font-weight: 400;
    font-family: "Jamsil";
    src: url('/font/The-Jamsil-OTF-3-Regular.otf') format('truetype');
  }

  @font-face {
    font-weight: 600;
    font-family: "Jamsil";
    src: url('/font/The-Jamsil-OTF-4-Medium.otf') format('truetype');

  }

  @font-face {
    font-weight: 700;
    font-family: "Jamsil";
    src: url('/font/The-Jamsil-OTF-5-Bold.otf') format('truetype');

  }

  @font-face {
    font-weight: 800;
    font-family: "Jamsil";
    src: url('/font/The-Jamsil-OTF-6-ExtraBold.otf') format('truetype');
  }
`;

export default GlobalStyles;
