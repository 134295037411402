import type { IconProps } from "../types";

export const FooterInstagramIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <g clipPath="url(#FooterInstagram_svg__a)">
      <path
        fill="#8B8B8B"
        d="M11.997 5.843A6.15 6.15 0 0 0 5.84 12a6.15 6.15 0 0 0 6.157 6.157A6.15 6.15 0 0 0 18.154 12a6.15 6.15 0 0 0-6.157-6.157m0 10.159A4.01 4.01 0 0 1 7.995 12a4.01 4.01 0 0 1 4.002-4.002A4.01 4.01 0 0 1 15.998 12a4.01 4.01 0 0 1-4.001 4.002m6.409-11.846c-.796 0-1.438.642-1.438 1.438s.642 1.438 1.438 1.438a1.434 1.434 0 0 0 1.329-1.989 1.44 1.44 0 0 0-1.329-.887M23.999 12c0-1.657.015-3.3-.078-4.953-.093-1.922-.532-3.627-1.937-5.032C20.576.607 18.874.172 16.953.08 15.296-.014 13.653 0 12 0c-1.657 0-3.3-.015-4.953.078C5.125.172 3.42.61 2.015 2.015.607 3.423.172 5.125.08 7.047-.014 8.704 0 10.346 0 12s-.015 3.3.078 4.953c.093 1.922.531 3.627 1.936 5.032 1.408 1.408 3.11 1.843 5.032 1.936C8.704 24.014 10.346 24 12 24c1.657 0 3.299.015 4.953-.078 1.921-.093 3.626-.531 5.031-1.936 1.408-1.408 1.843-3.11 1.937-5.032.096-1.654.078-3.296.078-4.953m-2.642 7.079c-.22.546-.483.954-.907 1.375a3.8 3.8 0 0 1-1.375.906c-1.579.628-5.328.487-7.078.487s-5.503.14-7.082-.484a3.8 3.8 0 0 1-1.375-.906 3.8 3.8 0 0 1-.906-1.375C2.009 17.5 2.15 13.75 2.15 12s-.14-5.503.484-7.082c.219-.546.483-.954.906-1.375a3.9 3.9 0 0 1 1.375-.906c1.58-.625 5.332-.484 7.082-.484s5.502-.14 7.081.484c.547.219.955.483 1.375.906.424.424.688.829.907 1.375.624 1.58.483 5.332.483 7.082s.141 5.5-.486 7.079"
      />
    </g>
    <defs>
      <clipPath id="FooterInstagram_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
