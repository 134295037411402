import type { IconProps } from "../types";

export const Home2Icon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21 19.857v-9.714a1.16 1.16 0 0 0-.45-.914l-7.875-6a1.11 1.11 0 0 0-1.35 0l-7.875 6a1.14 1.14 0 0 0-.45.914v9.714c0 .303.119.594.33.808.21.215.497.335.795.335h4.5c.298 0 .585-.12.795-.335.211-.214.33-.505.33-.808V16.43c0-.303.119-.594.33-.808.21-.215.497-.335.795-.335h2.25c.298 0 .585.12.796.335.21.214.329.504.329.808v3.428c0 .303.118.594.33.808.21.215.497.335.795.335h4.5c.298 0 .584-.12.796-.335.21-.214.329-.505.329-.808"
      stroke={strokeColor}
    />
  </svg>
);
