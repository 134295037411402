import { useEffect, useState } from "react";

export const STORAGE_KEY = {
  AUTH_EMAIL: "@auth/email",
  AUTH_REDIRECT: "@auth/redirectUrl",
  AUTH_TRIGGERED: "@auth/triggered",
  COMMON_HISTORY: "@common/history",
  COMMON_SURVEY: "@common/survey",
  COMMON_MARKETING: "@common/marketing",
  COMMON_APP_LINK: "@common/appLink", // session storage
} as const;

export type StorageKeyType = (typeof STORAGE_KEY)[keyof typeof STORAGE_KEY];

export default function useWebStorage<T>(
  key: StorageKeyType,
  initialValue: T,
  storageType: "localStorage" | "sessionStorage" = "localStorage",
) {
  const [storedValue, setStoredValue] = useState<T>(initialValue);

  // NOTE: 브라우저에서만 유효하기 떄문에 server-side 에서 사용 시 HTML miss match가 일어날 수 있음.
  useEffect(() => {
    try {
      const storage = window[storageType];
      const item = storage.getItem(key);
      setStoredValue(item ? JSON.parse(item) : initialValue);
    } catch (error) {
      console.error("Failed to load the stored value", error);
      setStoredValue(initialValue);
    }
  }, []);

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);

      window[storageType].setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error("Failed to set the stored value", error);
    }
  };

  const removeValue = () => {
    try {
      window[storageType].removeItem(key);
    } catch (error) {
      console.error("Failed to remove the stored value", error);
    }
  };

  return [storedValue, setValue, removeValue] as const;
}
