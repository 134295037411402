import type { IconProps } from "../types";

export const WaterDispenserIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path strokeWidth={2} d="M4 6h6v13H4z" stroke={strokeColor} />
    <path
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 20a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1H4z"
      stroke={strokeColor}
    />
    <path
      strokeWidth={2}
      d="M4 6h16V4a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2zM15 6h2v1h-2z"
      stroke={strokeColor}
    />
    <path
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M18 13.883c0 1.3-.773 2.117-2 2.117s-2-.818-2-2.117c0-1.395 1.434-3.277 1.874-3.821A.17.17 0 0 1 16 10q.036 0 .07.016.033.015.057.046c.44.544 1.873 2.426 1.873 3.82Z"
      stroke={strokeColor}
    />
  </svg>
);
