import WebViewBridge from "./webViewBridge";

/**
 * 현재 버전이 목표 버전보다 크거나 같은지 확인합니다.
 * @param {string} currentAppVersion - 현재 버전 문자열.
 * @param {string} targetVersion - 목표 버전 문자열.
 * @returns {boolean} - 현재 앱 버전이 목표 버전보다 크거나 같으면 true, 그렇지 않으면 false.
 *
 * @example
 * ```
 * // 현재 버전이 1.0.4이고 목표 버전이 1.0.3인 경우
 * console.log(isVersionGreaterOrEqual("1.0.4", "1.0.3")); // true
 *
 * // 현재 버전이 1.0.2이고 목표 버전이 1.0.3인 경우
 * console.log(isVersionGreaterOrEqual("1.0.2", "1.0.3")); // false
 *
 * // 현재 버전이 1.0.3이고 목표 버전이 1.0.3인 경우
 * console.log(isVersionGreaterOrEqual("1.0.3", "1.0.3")); // true
 * ```
 */
export const isVersionGreaterOrEqual = (
  currentAppVersion: string,
  targetVersion: string,
): boolean => {
  const currentParts = currentAppVersion.split(".").map(Number);
  const targetParts = targetVersion.split(".").map(Number);

  for (let i = 0; i < Math.max(currentParts.length, targetParts.length); i++) {
    const currentPart = currentParts[i] || 0;
    const targetPart = targetParts[i] || 0;

    if (currentPart > targetPart) {
      return true;
    }
    if (currentPart < targetPart) {
      return false;
    }
  }

  return true;
};

/**
 * 앱 버전을 가져와서 목표 버전보다 크거나 같은지 확인합니다.
 * @param {string} targetVersion - 목표 버전 문자열.
 * @returns {Promise<boolean>} - 현재 버전이 목표 버전보다 크거나 같으면 true, 그렇지 않으면 false.
 *
 * @example
 * ```
 * // 비동기로 현재 앱 버전이 1.0.3 이상인지 확인합니다.
 * (async () => {
 *   const isVersionValid = await isCurrentAppVersionGreaterOrEqual("1.0.3");
 *   console.log(isVersionValid); // 예를 들어, 현재 버전이 1.0.3 이상이면 true를 출력
 * })();
 * ```
 */
export const isCurrentAppVersionGreaterOrEqual = async (
  targetVersion: string,
): Promise<boolean> => {
  if (!WebViewBridge.isInAppWebView()) return true;

  const currentAppVersion =
    await WebViewBridge.getInstance().triggerCheckAppVersion();

  if (!currentAppVersion) return false;

  return isVersionGreaterOrEqual(currentAppVersion, targetVersion);
};
