import type { IconProps } from "../types";

export const LoginAppleIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      fill="#fff"
      d="M18.55 12.762a5.47 5.47 0 0 1 2.591-4.577 5.58 5.58 0 0 0-4.398-2.38c-1.844-.195-3.642 1.107-4.583 1.107-.94 0-2.406-1.09-3.974-1.052a5.84 5.84 0 0 0-4.924 3.008c-2.13 3.691-.544 9.108 1.503 12.097 1.024 1.458 2.213 3.092 3.771 3.036 1.531-.064 2.094-.978 3.938-.978s2.36.978 3.956.942c1.64-.028 2.674-1.468 3.651-2.944a11.8 11.8 0 0 0 1.669-3.405 5.28 5.28 0 0 1-3.218-4.845zM15.543 3.848A5.35 5.35 0 0 0 16.769 0a5.47 5.47 0 0 0-3.532 1.827 5.13 5.13 0 0 0-1.263 3.7 4.49 4.49 0 0 0 3.56-1.688z"
      stroke={strokeColor}
    />
  </svg>
);
