import type { IconProps } from "../types";

export const SolidQuestionMarkCircleIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <circle cx={12} cy={12} r={12} fill="#ECECEC" stroke={strokeColor} />
    <path
      fill="#8B8B8B"
      d="M12.61 14.746h-1.832q.01-.968.168-1.583.167-.624.542-1.139.374-.513.995-1.17.453-.472.827-.886.384-.424.621-.908.237-.493.237-1.179 0-.696-.247-1.2a1.7 1.7 0 0 0-.71-.776q-.462-.272-1.152-.272-.57 0-1.084.212a1.87 1.87 0 0 0-.827.655q-.316.433-.325 1.14H8q.02-1.14.552-1.957a3.5 3.5 0 0 1 1.458-1.25Q10.925 4 12.06 4q1.25 0 2.127.464.887.463 1.35 1.33.463.857.463 2.037 0 .906-.364 1.673a6.4 6.4 0 0 1-.917 1.421 17 17 0 0 1-1.192 1.27q-.542.515-.729 1.16t-.187 1.391m-1.91 3.196q0-.454.275-.767.276-.312.798-.312.532 0 .808.312.276.313.276.767 0 .433-.276.745-.276.313-.808.313-.522 0-.798-.312a1.1 1.1 0 0 1-.275-.746"
      stroke={strokeColor}
    />
  </svg>
);
