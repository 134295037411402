import type { IconProps } from "../types";

export const LoginKakaoIcon = ({
  size = 24,
  fillColor = "none",
  strokeColor = "#515151",
  className = "",
  style = {},
  viewBox = "0 0 24 24",
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fillColor}
    style={style}
    className={className}
    viewBox={viewBox}
  >
    <path
      fill="#000"
      d="M24 10.179C24 4.557 18.627 0 12 0S0 4.558 0 10.179c0 3.576 2.177 6.723 5.469 8.538l-1.02 4.574c-.235.807-.033.843.54.514l5.037-3.585a14 14 0 0 0 1.974.136c6.627 0 12-4.556 12-10.177"
      stroke={strokeColor}
    />
  </svg>
);
